.loader {
  z-index: -2;
}

.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;

    ul {
      opacity: 0;
      padding: 0;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #7bb5cb;
    }
  }

  h1 {
    color: #fff;
    font-size: 58px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
  }

  h2 {
    color: #bdcddc;
    padding-top: 10px;
    margin-top: 25px;
    font-weight: 700;
    font-size: 13px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #e5bccc;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 8px 8px 10px;
    border: 1px solid #e5bccc;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;
    border-width: 2px;
    border-radius: 6px;

    &:hover {
      background: #e5bccc;
      color: #414e78;
      transition: all 0.5s ease;
    }
  }
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1::before,
  .home-page h1::after {
    display: none;
  }

  .home-page {
    .text-zone {
      position: initial;
      transform: none;
      padding: 20px;
      box-sizing: border-box;

      h1 {
        margin-top: 200px;
        margin-left: -10px;
      }

      ul {
        opacity: 1;
        list-style: none;
        margin-top: 30px;
        display: flex;
        animation: fadeIn 1s 2s backwards;

        li {
          font-size: 40px;
          margin: 0 5px;

          &:nth-child(1) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .home-page {
    .text-zone {
      h1 {
        margin-top: 100px;
      }

      ul {
        margin: 40px auto;
        width: 120px;
        justify-content: space-around;
      }
    }

    .flat-button {
      float: none;
      display: block;
      margin: 40px auto;
      width: 120px;
      text-align: center;
    }
  }
}
