.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  min-height: 500px;
  z-index: 2;

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #7bb5cb;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.projects-link {
      &:after {
        content: 'PROJECTS';
      }
    }

    a.active {
      svg {
        color: #7bb5cb;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 30px;
        line-height: 16px;

        &:hover svg {
          color: #7bb5cb;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: absolute;
    height: 100vh;
    min-height: auto;
    width: 100%;
    z-index: 0;

    ul,
    nav {
      display: none;
    }

    nav {
      width: 100%;
      bottom: 0;
      left: 0;
      background: #181818;
      height: auto;
      margin: 0;
      z-index: 2;

      &.mobile-show {
        margin-top: auto;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        height: fit-content;
      }

      a {
        padding-top: 5px;
        width: auto;
        min-width: 70px;
        height: auto;
      }

      a:after {
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }

      a svg.active {
        opacity: 0;
      }
    }
  }
}
