.projects-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 200px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    margin-left: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);
    box-shadow: 3px 3px 5px #6778a3;
    animation: pulse 1s;

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        animation-delay: 1s + ($i * 0.1s);
      }
    }

    .project-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px 0;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0,
        rgba(0, 0, 0, 1)
      );
      bottom: -70px;

      h2,
      h4 {
        padding: 0 20px;
      }
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
      color: #fff;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      margin-left: 20px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #e5bccc;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #e5bccc;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #e5bccc, #6778a3);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.9;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container.projects-page {
    h1 {
      margin-top: 40px;
      margin-left: -80px;
    }
    .images-container {
      margin-left: -40px;

      .image-box {
        min-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .container.projects-page {
    width: 100%;
    padding: 40px 20px 20px 20px;
    box-sizing: border-box;

    h1.page-title {
      margin-left: 0;
      margin-top: 0;
    }

    .images-container {
      margin-left: 0;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .image-box {
        height: 300px;
        // If theres more than 1 project, change from 100% to 50% in the calc()
        max-width: calc(100% - 10px);
        width: 100%;
        flex: none;
      }
    }
  }
}
