p {
  font-size: 14px;
  color: #fff;
  font-family: sans-serif;
  font-weight: 300;
  min-width: fit-content;
  animation: pulse 1s;

  &:nth-of-type(1) {
    animation-delay: 1.1s;
  }
  &:nth-of-type(2) {
    animation-delay: 1.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 1.3s;
  }

  .technologies {
    color: #7bb5cb;
    font-weight: 700;
  }
}
