@use 'sass:math';

.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;

  &:hover {
    animation: rubberBand 0.5s;
    color: #7bb5cb;
  }
}

@for $i from 1 through 35 {
  .text-animate._#{$i} {
    animation-delay: #{math.div($i, 10)}s;
  }
}
